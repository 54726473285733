<template>
  <div class="layout">
    <sword-header />

    <main class="layout-main">
      <slot v-if="$slots.default" />
      <router-view v-else />
    </main>

    <sword-footer />
  </div>
</template>

<script>
import SwordHeader from '@/components/layout/SwordHeader.vue';
import SwordFooter from '@/components/layout/SwordFooter.vue';

export default {
  name: 'SwordLayout',

  components: {
    SwordHeader,
    SwordFooter,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.layout-main {
  display: flex;
  flex: 1;
  padding: map-get($sh-spacers-wuk, 5) map-get($sh-spacers-wuk, 0);
}
</style>
