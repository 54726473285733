<template>
  <footer class="reset footer">
    <a :href="supportMailTo">
      {{ supportEmail }}
    </a>
    <p>
      {{ $t('copy_right') }}
    </p>
  </footer>
</template>

<script>
import { EMAILS } from '@/configs/constants';

export default {
  name: 'SwordFooter',

  computed: {
    supportEmail() {
      return EMAILS.help || 'help@swordhealth.com';
    },
    supportMailTo() {
      return `mailto:${this.supportEmail}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: map-get($sh-spacers-wuk, 3);
  padding: map-get($sh-spacers-wuk, 3) map-get($sh-spacers-wuk, 5);

  background: getColor($greys, 8);
  color: getColor($greys, 1);
  font-size: $font-base-12;
  text-align: center;

  @media screen and (min-width: $tablet-size) {
    flex-direction: row;
  }
}
</style>
