<template>
  <header class="header">
    <img class="sword-logo"
         src="@/assets/full-logo.svg"
         alt="SWORD Health">
  </header>
</template>

<script>
export default {
  name: 'SwordHeader',
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: map-get($sh-spacers-wuk, 2) map-get($sh-spacers-wuk, 5);

  @media screen and (min-width: $tablet-size) {
    justify-content: flex-start;
  }
}

.sword-logo {
  height: 2rem;
  width: 9rem;
}
</style>
