import { interpret } from '@xstate/fsm';

import {
  DEFAULT_ACTIVITY_BROWSER_EVENTS,
  DEFAULT_INACTIVITY_THRESHOLD,
  FSM_ACTIONS,
  FSM_STATES,
  ONE_SEC_IN_MS,
} from '@/scripts/dpt-activity/constants';
import toggleMachine from '@/scripts/dpt-activity/fsm_machine';

/**
 * General debouncing function
 *
 * @param func - the function you want to debounce
 * @param timeout - the number of ms that you want to debounce for
 * @returns {(function(...[*]): void)|*}
 */
const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

/**
 * Handles event listening for showing/hiding screen based on Page Visiblity API
 *
 * @param service - [XState FSM service]{@link https://xstate.js.org/docs/packages/xstate-fsm/#interpret-machine}
 */
const createPageVisibilityEventListener = service => {
  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      service.send(FSM_ACTIONS.HIDE_SCREEN);
    } else {
      service.send(FSM_ACTIONS.SHOW_SCREEN);
    }
  });
};

/**
 * Used to create the different user activity event listeners on the pages
 *
 * @param service - [XState FSM service]{@link https://xstate.js.org/docs/packages/xstate-fsm/#interpret-machine}
 */
const createPageActivityEventListener = service => {
  const sendActivityEvent = debounce(() => {
    service.send(FSM_ACTIONS.ACTIVITY);
  }, 500);

  DEFAULT_ACTIVITY_BROWSER_EVENTS.forEach(event => {
    document.addEventListener(event, debounce(() => {
      // Only trigger activity event if the screen is visible to the user
      if (!document.hidden) {
        sendActivityEvent();
      }
    }), 500);
  });
};

/**
 * Creates an interval dedicated to handling inactivity timeout
 *
 * @param service - [XState FSM service]{@link https://xstate.js.org/docs/packages/xstate-fsm/#interpret-machine}
 * @param inactivityTimer
 * @returns {number} - the ID of the interval
 */
export const createPageInactivityInterval = (service, inactivityTimer) => setInterval(() => {
  if (inactivityTimer.getTotalTime() >= DEFAULT_INACTIVITY_THRESHOLD) {
    service.send(FSM_ACTIONS.INACTIVITY);
  }
}, ONE_SEC_IN_MS);

/**
 * Handles kicking off the DPT Activity/Inactivity/Page Visibility finite state machine service
 *
 * @param service - [XState FSM service]{@link https://xstate.js.org/docs/packages/xstate-fsm/#interpret-machine}
 */
export const startActivityTrackingService = service => {
  createPageActivityEventListener(service);
  createPageVisibilityEventListener(service);

  service.start();
  service.send(FSM_ACTIONS.ACTIVITY);
};

/**
 * Handles starting, restarting and pausing the correct Timers depending on the user state (activity/inactivity) and window state (page visibility)
 *
 * @param service - [XState FSM service]{@link https://xstate.js.org/docs/packages/xstate-fsm/#interpret-machine}
 */
export const createFSMService = (activityTimer, inactivityTimer) => {
  const service = interpret(toggleMachine);

  service.subscribe(state => {
    // eslint-disable-next-line default-case
    switch (state.value) {
      case FSM_STATES.ACTIVE:
        activityTimer.start();
        inactivityTimer.restart();
        break;
      case FSM_STATES.INACTIVE:
        activityTimer.pause();
        break;
      case FSM_STATES.SCREEN_HIDDEN:
        activityTimer.pause();
        inactivityTimer.reset();
        break;
    }
  });

  return service;
};

/**
 * Computes the difference between start/end times
 *
 * @param startDate - the starting date of the date range
 * @param endDate - the ending date of the date range
 * @returns {number} - the difference in start/end times in seconds (rounded down)
 */
export const computeDiffInSeconds = (startDate, endDate) => Math.floor((endDate - startDate) / ONE_SEC_IN_MS);
