<template>
  <sword-layout>
    <div class="reset loading-wrapper">
      <pulse-loader-v2 />
      <p v-if="message" class="loading-message">
        {{ message }}
      </p>
    </div>
  </sword-layout>
</template>

<script>
import SwordLayout from '@/components/layout/SwordLayout.vue';
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';

export default {
  name: 'LoadingScreen',

  components: {
    SwordLayout,
    PulseLoaderV2,
  },

  props: {
    message: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-message {
  margin-top: map-get($sh-spacers-wuk, 3);
}
</style>
