import { createMachine } from '@xstate/fsm';

const toggleMachine = createMachine({
  id: 'activityTracker',
  initial: 'initialized',
  states: {
    initialized: {
      on: {
        ACTIVITY: 'active',
      },
    },
    active: {
      on: {
        ACTIVITY: 'active',
        INACTIVITY: 'inactive',
        HIDE_SCREEN: 'hidden',
      },
    },
    inactive: {
      on: {
        ACTIVITY: 'active',
        HIDE_SCREEN: 'hidden',
      },
    },
    hidden: {
      on: {
        ACTIVITY: 'hidden',
        SHOW_SCREEN: 'active',
      },
    },
  },
});

export default toggleMachine;
