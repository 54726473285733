export const PAUSED_STATUS = 'paused';
export const ACTIVE_STATUS = 'active';

export const ONE_SEC_IN_MS = 1000;

// The number of seconds that a PT user can be inactive for (i.e., the time of not having had a browser event occur
export const DEFAULT_INACTIVITY_THRESHOLD = 60;

// The number of seconds between DPT activity updates to the backend
export const DEFAULT_HEARTBEAT_INTERNAL = 5;

export const DEFAULT_ACTIVITY_BROWSER_EVENTS = [ 'keydown', 'mousemove', 'mousedown' ];

export const FSM_STATES = {
  INITIALIZED: 'initialized',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  SCREEN_HIDDEN: 'hidden',
};

export const FSM_ACTIONS = {
  ACTIVITY: 'ACTIVITY',
  INACTIVITY: 'INACTIVITY',
  HIDE_SCREEN: 'HIDE_SCREEN',
  SHOW_SCREEN: 'SHOW_SCREEN',
};
